import { IMAGE_TRIM } from "react-app/src/constants";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { useSharedData } from "../../../contexts/SharedData";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { Slider } from "../../Common/Slider";
import {
  DEFAULT_GRID_VALUE_DESKTOP,
  DEFAULT_GRID_VALUE_MOBILE,
  SLIDER_OPTIONS,
} from "../constants";
import { getProductImageSizes } from "../product-helper";
import { ProductImage } from "./ProductImage";

/**
FIXME:We should not be importing from next-app into react app.
This is a temporary solution to allow us to use the NextImage component in the react app,
since the stub NextImage component does not work.
XD-15933
*/
import { NextImage } from "../../../../../next-js-app/src/components/common/NextImage/NextImage";

const { spaces } = xxlTheme;

type MobileColumnAmount = 1 | 2;
type DesktopColumnAmount = 4 | 6;

const MobileImageCarousel = ({
  listOfProductUrls,
  isImgPrioPossible,
  imageAltText,
  columnAmount = DEFAULT_GRID_VALUE_MOBILE,
  isReactApp,
}: {
  listOfProductUrls: string[];
  isImgPrioPossible: boolean;
  imageAltText?: string;
  columnAmount: 1 | 2;
  isReactApp: boolean;
}) => {
  const imageSize = getProductImageSizes({
    device: "mobile",
    columnAmount: columnAmount,
  });

  const products = listOfProductUrls.map((image, index) => {
    const isPrio = index === 0 && isImgPrioPossible;

    if (isReactApp) {
      return (
        <ProductImage
          key={index}
          alt={imageAltText}
          columnAmount={columnAmount}
          size={imageSize}
          fetchPriority={isPrio ? "high" : "low"}
          imageUrl={image}
          loading={isPrio ? "eager" : "lazy"}
          trim={IMAGE_TRIM}
        />
      );
    }

    return (
      <NextImage
        key={index}
        src={image}
        alt={imageAltText ?? "product image"}
        height={imageSize.h}
        width={imageSize.w}
        priority={isPrio}
        trim={IMAGE_TRIM}
      />
    );
  });

  return (
    <Slider
      items={products}
      slidesConfig={{
        perView: SLIDER_OPTIONS.slidesPerView,
        spacing: SLIDER_OPTIONS.spacing,
      }}
      mode={SLIDER_OPTIONS.mode}
      dragSpeed={SLIDER_OPTIONS.dragSpeed}
      loadInitiallyOnlyFirstSlide={true}
      navigation={"ARROWS_SMALL"}
      arrowSmallVariant="gray"
      containerPadding={`0 ${spaces.miniMicro}`}
    />
  );
};

type Props = {
  columnAmount: number;
  imageAltText?: string;
  isFocused?: boolean;
  listOfProductUrls: string[];
  positionInList?: number;
  primaryImageUrl?: string;
  prioritizeImageLoad?: boolean;
  showOnlyOneImage?: boolean;
};

const MobileImageCarouselOrSingleProductImage = ({
  columnAmount = DEFAULT_GRID_VALUE_DESKTOP,
  imageAltText,
  isFocused = false,
  listOfProductUrls,
  positionInList = -1,
  primaryImageUrl,
  prioritizeImageLoad = false,
  showOnlyOneImage = false,
}: Props) => {
  const { isReactApp } = useSharedData();
  const isMobileOrTablet = useXxlMediaQuery("MobileAndTabletMediaQuery");

  const isImgPrioPossible =
    prioritizeImageLoad &&
    (isMobileOrTablet && positionInList !== -1
      ? positionInList < 2
      : positionInList < 6);

  const shouldRenderSlider =
    isMobileOrTablet && listOfProductUrls.length > 1 && !showOnlyOneImage;

  const shouldRenderMobileImage =
    isMobileOrTablet && (columnAmount === 1 || columnAmount === 2);

  const imageSize = shouldRenderMobileImage
    ? getProductImageSizes({ device: "mobile", columnAmount })
    : getProductImageSizes({
        device: "desktop",
        columnAmount: columnAmount as DesktopColumnAmount,
      });

  if (shouldRenderSlider) {
    return (
      <MobileImageCarousel
        listOfProductUrls={listOfProductUrls}
        isImgPrioPossible={isImgPrioPossible}
        columnAmount={columnAmount as MobileColumnAmount}
        isReactApp={isReactApp}
      />
    );
  }

  const [, secondImage] = listOfProductUrls;

  return (
    <ProductImage
      alt={imageAltText}
      columnAmount={columnAmount}
      fetchPriority={isImgPrioPossible ? "high" : "low"}
      focusImageUrl={secondImage}
      imageUrl={primaryImageUrl}
      isFocused={isFocused}
      loading={isImgPrioPossible ? "eager" : "lazy"}
      size={imageSize}
      trim={IMAGE_TRIM}
    />
  );
};

export { MobileImageCarouselOrSingleProductImage };
