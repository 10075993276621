import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
const { typography, spaces } = xxlTheme;

export const RatingContainer = styled.div`
  display: flex;
  gap: ${spaces.line};
`;

export const AverageRatingText = styled.span`
  ${typographyToCss(typography.smallMedium)};
`;
